import { NetworkDashboardComing } from "./dashboardComing";
import { NetworkContactLookup } from "./contactLookup";
import { useAuthContext } from "@/app/contexts/auth";


// TODO: get rid of observer after auth/user refactor
export const NetworkDashboard = function NetworkDashboard() {
  const { myRoles } = useAuthContext();
  // not loaded yet
  if (myRoles === null) return null;

  if (myRoles.includes("USER_ROLE_NETWORK")) {
    return <NetworkContactLookup />;
  }

  return <NetworkDashboardComing />;
};
