import { BreadCrumbs } from "@/app/components";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useCallback } from "react";
import { useNavigate } from "react-router";
import { X } from "lucide-react";

// TODO: use proper source for button style
const buttonClasses =
  "flex select-none items-center justify-center text-sm font-semibold disabled:pointer-events-none h-10 rounded-xs px-4 py-3 cursor-pointer bg-gradient-to-r from-primary via-[#E93E62] to-secondary text-white hover:from-[#FF3B76] hover:to-[#FF792D] hover:opacity-90 active:from-[#CC2F5E] active:to-[#CC6124E5]/90 disabled:bg-gray-200 disabled:bg-none disabled:text-gray-500 w-full min-w-[0px] gap-4";

const closeButtonClasses = "inline hover:bg-gray-200 p-2 rounded-lg";
const dateFormat = new Intl.DateTimeFormat("en-GB", {
  day: "numeric",
  month: "short",
  year: "numeric",
});

const NETWORK_GET_BLACKLIST = gql`
  query NetworkGetBlacklist {
    networkGetBlacklist {
      nodes {
        id
        value
        type
        added
      }
    }
  }
`;

const NETWORK_ADD_BLACKLIST_ITEM = gql`
  mutation NetworkAddBlacklistValue($value: String) {
    networkAddBlacklistValue(input: { blacklistValue: $value }) {
      networkUgcBlacklists {
        id
        userEmail
        value
        type
        added
      }
    }
  }
`;

const NETWORK_REMOVE_BLACKLIST_ITEM = gql`
  mutation NetworkAddBlacklistItem($id: BigInt) {
    networkRemoveBlacklistItem(input: { id: $id }) {
      networkUgcBlacklists {
        id
        userEmail
        value
        type
        added
      }
    }
  }
`;

export function NetworkUserSettings() {
  const { data, loading: queryLoading } = useQuery(NETWORK_GET_BLACKLIST);
  const [mutationAdd, { loading: mutationAddLoading }] = useMutation(NETWORK_ADD_BLACKLIST_ITEM, {
    update(cache, { data: { networkUgcBlacklists } }) {
      cache.writeQuery({
        query: NETWORK_GET_BLACKLIST,
        data: {
          networkGetBlacklist: { nodes: { networkUgcBlacklists } },
        },
      });
    },
  });

  const [mutationRemove, { loading: mutationRemoveLoading }] = useMutation(NETWORK_REMOVE_BLACKLIST_ITEM, {
    update(cache, { data: { networkUgcBlacklists } }) {
      cache.writeQuery({
        query: NETWORK_GET_BLACKLIST,
        data: {
          networkGetBlacklist: { nodes: { networkUgcBlacklists } },
        },
      });
    },
  });

  const loading = mutationAddLoading || mutationRemoveLoading || queryLoading;

  const navigate = useNavigate();
  const handleItemAdd = useCallback(
    (value: string) => {
      mutationAdd({ variables: { value } });
    },
    [mutationAdd]
  );

  const handleTableClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      if (event.target instanceof Element) {
        event.preventDefault();
        if (loading) return;
        const id = event.target?.closest("[data-remove-filter-item-id]")?.getAttribute("data-remove-filter-item-id");

        if (id) {
          mutationRemove({ variables: { id } });
        }
      }
    },
    [mutationRemove, loading]
  );

  const goBack = useCallback(() => {
    navigate(`/network`);
  }, []);

  return (
    <div className="max-w-[1400px] w-full p-4">
      <div className="flex flex-col gap-4 px-4 lg:px-0">
        <BreadCrumbs
          title="Network"
          action={goBack}
          elementProps={{
            title: { className: "block text-sm" },
            icon: {
              className: "w-4 h-4",
            },
          }}
        />
      </div>

      <div className="overflow-hidden text-ellipsis line-clamp-1 text-[24px] font-bold my-4">
        Personal Network Filters
      </div>
      <div className="rounded-md bg-white p-4 my-4 text-[16px]">
        Manage a personal Network filter to exclude specific email addresses.
        <br /> Matched emails from your Inbox (sent or received) would not be included in Network computations.
        <br />
        <span className="font-bold">Note:</span> It may take up to 24 hours for the metrics to be recomputed after
        making changes.
      </div>
      <div className="flex gap-4">
        <div onClick={handleTableClick} className="w-3/4 rounded-md bg-white p-4 relative self-start">
          <BlacklistTable loading={loading} blacklist={data?.networkGetBlacklist?.nodes as BlacklistItem[]} />
        </div>

        <div className="w-1/4 rounded-md bg-white p-4 relative self-start">
          <AddBlackListValueForm onItemAdd={handleItemAdd} loading={loading} />
        </div>
      </div>
    </div>
  );
}

type BlacklistItem = {
  id: string;
  value: string;
  type: string;
  added: string;
};

type BlacklistTableProps = {
  blacklist?: BlacklistItem[];
  loading: boolean;
};
export function BlacklistTable(props: BlacklistTableProps) {
  const blacklist = props.blacklist || [];
  const { loading } = props;
  return (
    <div>
      <table className="w-full divide-y text-[14px]">
        <thead>
          <tr>
            <th className="font-medium text-neutral text-left p-2">Value</th>
            <th className="w-[1px] whitespace-nowrap font-medium text-neutral text-left p-2">Type</th>
            <th className="w-[1px] whitespace-nowrap font-medium text-neutral text-left p-2">Date Added</th>
            <th className="w-[1px]"></th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {(!blacklist.length && loading) ? (
            <tr>
              <td colSpan={4}>
                <div className="animate-pulse-original">
                  <div className="space-y-3">
                    <div className="h-4 bg-neutral-300 rounded col-span-2"></div>
                    <div className="h-4 bg-neutral-300 rounded col-span-1"></div>
                  </div>
                </div>
              </td>
            </tr>
          ) : blacklist.length ? (
            blacklist.map((item: BlacklistItem) => {
              return (
                <tr key={item.id} className="group hover:bg-gray-100">
                  <td className="p-2 py-1 font-medium text-neutral group-hover:text-black">{item.value}</td>
                  <td className="whitespace-nowrap capitalize p-2 py-1">{item.type.replace("_", " ").toLowerCase()}</td>
                  <td className="whitespace-nowrap p-2 py-1">{dateFormat.format(new Date(item.added))}</td>
                  <td>
                    <span className="invisible group-hover:visible p-2 py-1">
                      <button data-remove-filter-item-id={item.id} className={closeButtonClasses}>
                        <X size={16} />
                      </button>
                    </span>
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>
                {" "}
                <div className="text-center my-5">You don't have any filters yet</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

type AddBlacklistValueFormProps = {
  onItemAdd: (value: string) => void;
  loading: boolean;
};

export function AddBlackListValueForm(props: AddBlacklistValueFormProps) {
  const { onItemAdd, loading } = props;
  const disabled = loading;
  const handleSubmit = useCallback((event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (event.target instanceof HTMLFormElement) {
      const formData = new FormData(event.target);
      const value = formData.get("blacklist-value");
      onItemAdd(value as string);
    }
  }, []);
  return (
    <form onSubmit={handleSubmit}>
      <div className="my-1 text-neutral">
        An <span className="font-medium text-black">email address</span> or a{" "}
        <span className="font-medium text-black">domain</span>
      </div>
      <input
        autoComplete="off"
        type="text"
        placeholder="sensitive@example.com"
        name="blacklist-value"
        className="my-2 w-full rounded-md border border-neutral-300 px-3 py-2"
      />
      <input type="submit" value="Add" disabled={disabled} className={buttonClasses} />
    </form>
  );
}
