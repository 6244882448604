import { observer } from "mobx-react";

import { cn } from "@/lib/utils.ts";

import { Text } from "@/app/components";
import { useDashboardSettings } from "@/app/screens/settings/hooks";
import { useNavigate } from "react-router";
import { useAuthContext } from "@/app/contexts/auth";

export const SettingsDashboard = observer(() => {
  const { name, tabs, TabComponent, activeTab, setActiveTab } = useDashboardSettings();
  const navigate = useNavigate();
  const { myRoles } = useAuthContext();
  const networkSettings = myRoles?.includes("USER_ROLE_NETWORK") ? (
    <span
      className={"cursor-pointer select-none text-sm font-medium text-gray hover:text-gray-600"}
      onClick={() => navigate("/network/settings/personal-filters")}
    >
      Personal Network Filters
    </span>
  ) : null;

  return (
    <div className={"relative min-h-dvh w-full pt-[10dvh] lg:pt-0"}>
      <div className={"my-8 flex w-full flex-col px-[7dvw] lg:px-[14dvw]"}>
        <div>
          <Text text={name} type={"h4"} />
          <Text
            text={"Manage your details and personal preferences here."}
            color={"text-neutral"}
            weight={"normal"}
            className={"mt-3"}
          />
        </div>
        <div className={"my-12 flex space-x-4.5"}>
          {tabs.map((tab) => {
            const isActive = tab === activeTab;
            return (
              <Text
                key={tab}
                text={tab.replace(/([A-Z])/g, " $1").trim()}
                className={cn("cursor-pointer")}
                weight={isActive ? "bold" : "medium"}
                color={isActive ? "text-neutral-900" : "text-neutral"}
                onClick={() => setActiveTab(tab)}
              />
            );
          })}
          {networkSettings}{" "}
        </div>
        <div className={"w-full"}>
          <TabComponent />
        </div>
      </div>
    </div>
  );
});
